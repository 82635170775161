@import "../../styles/_chunk";

.fanatical-blog {
  background-color: $blog-bg-color;
  color: $text-black;
  margin-bottom: -1.5rem;
  padding-bottom: 1.5rem;

  a {
    color: $primary-core;
  }

  .blog-products-container {
    background-color: $blog-bg-inverse-color;
    margin-bottom: 2rem;

    .HitCardContainer {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 2rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .HitCard,
      .challenger-hit-card {
        margin-bottom: 0 !important;
      }
    }
  }

  .related-button {
    @include button-variant($primary-core, $primary-core);

    padding: 10px;
    display: block;
    color: $primary-button-text !important;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
  }

  .rss-btn {
    padding: 5px;
    display: flex;
    align-items: center;

    .rss-btn-icon {
      float: left;
      background-color: $grey-14;
      color: $grey-2;
      padding: 0.5rem 0.6rem;
      align-items: flex-start;
      border-radius: $border-radius-sm;
    }

    b,
    p {
      flex-grow: 1;
      align-self: center;
      margin: 0;
      font-size: 1.125rem;
    }
  }

  .featured-deal-card {
    box-shadow: none;
  }

  .blog-search-container {
    .input-group {
      input {
        border-color: map-get($light-theme, input-border-color);
      }
    }
  }
}
