@import "../../styles/_chunk";

.blog-categories-container {
  .active-category {
    a {
      color: $primary-core !important;
    }
  }

  .no-border {
    border: 0 !important;
  }

  border-radius: $border-radius;
  display: flex;
  font-weight: 800;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: $blog-bg-inverse-color;
  padding: 0.5rem;
  margin-bottom: 1.5rem;

  .blog-category {
    text-align: center;

    a {
      font-size: 0.875rem;
      color: $text-black;
    }
  }

  @include media-breakpoint-up(md) {
    justify-content: space-around;
    background-color: $blog-bg-color;

    .blog-category {
      text-align: center;
      border: 3px solid $primary-core;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      flex-grow: 1;

      a {
        font-size: 1.85rem;
        color: $text-black;
      }
    }
  }
}

.blog-category-dummy-top {
  padding-top: 2rem !important;
}
