@import "../../../styles/_chunk";

$responsive-island-grey: #a8a8a8;

.BlogAuthorBio {
  display: flex;
  align-items: center;

  .BlogAuthorBio_profilePicture {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    margin-right: 0.5rem;
    flex-shrink: 0;
    background-color: $responsive-island-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      border-radius: 50px;
    }

    svg {
      font-size: 3rem;
    }

    &.isHomePage {
      width: 80px;
      height: 80px;
      margin-right: 15px;
    }
  }

  .BlogAuthorBio_content {
    .BlogAuthorBio_authorName {
      font-weight: 700;
      margin-bottom: 0;
    }

    .BlogAuthorBio_authorName_link {
      font-size: 1.125rem;
      font-weight: 700;

      &:hover {
        color: $primary-core;
      }
    }

    .BlogAuthorBio_authorBio {
      font-size: 1rem;
    }
  }
}
