// Colours
$primary: #ff9800; // $orange-500
$primary-core: #ff9800; // $orange-500 TODO Switch from using $primary to $primary-core
$primary-dark: #d98100;
$secondary: #2196f3;
$dlc: #8e24aa; // $purple-600

// Star Deal
$stardeal-core: #26c6da; // $cyan-400
$stardeal-mid: #00acc1; // $cyan-600
$stardeal-dark: #00838f; // $cyan-800

// Greyscale
$white: #fff;
$grey-1: #f5f5f5; // $grey-100
$grey-2: #eee; // $grey-200
$grey-3: #e0e0e0; // $grey-300
$grey-4: #bdbdbd; // $grey-400
$grey-5: #9e9e9e; // $grey-500
$grey-6: #858585;
$grey-7: #757575; // $grey-600
$grey-8: #616161; // $grey-700
$grey-9: #515151;
$grey-10: #424242; // $grey-800
$grey-11: #333;
$grey-12: #2b2b2b;
$grey-13: #212121; // $grey-900
$grey-14: #1c1c1c;
$grey-15: #141414;
$grey-16: #0d0d0d;
$black: #000;

// Messaging
$error-core: #f44336; // $red-500
$error-dark: #d32f2f; // $red-700
$success-core: #4caf50; // $green-500
$success-dark: #388e3c; // $green-700
$info-core: #2196f3; // $blue-500
$info-dark: #1976d2; // $blue-700
$warning-core: #ffc107; // $amber-500
$warning-dark: #ffa000; // $amber-700

//Typography
$font-color-white: $white;
$font-color-light: $grey-2;
$font-color-dark: $grey-13;
$font-color-black: $black;
$font-color-muted: $grey-5;

// Buttons
$primary-button-background: $primary-core;
$primary-button-text: $grey-14;

// Cards
$card-background-dark: $grey-12;

// Labels
$label-primary: $primary-core;
$label-primary-dark: #ef6c00; // $orange-800
$label-stardeal: $stardeal-core;
$label-stardeal-dark: $stardeal-dark;
$label-purple: #8e24aa; // $purple-600
$label-purple-dark: #4a148c; // $purple-900
$label-green: #43a047; // $green-600
$label-green-dark: #1b5e20; // $green-900
$label-red: #d32f2f; // $red-700
$label-red-dark: #b71c1c; // $red-900
$label-orange: #f4511e; // $deep-orange-600
$label-orange-dark: #bf360c; // $deep-orange-900
$label-navy: #3949ab; // $indigo-600
$label-navy-dark: #1a237e; // $indigo-900

// Reviews
$five-star-green: #4caf50; // $green-500
$four-star-green: #8bc34a; // $light-green-500
$three-star-yellow: #fdd835; // $yellow-600
$two-star-orange: #ff9800; // $orange-500
$one-star-red: #e53935; // $red-600
$zero-star-grey-dark: $grey-8;
$zero-star-grey-light: $grey-3;
$thumb-green: #4caf50; // $green-500
$thumb-green-dark: #388e3c; // $green-700
$thumb-red: #e53935; // $red-600
$thumb-red-dark: #c62828; // $red-800

// Mystery Grades
$mystery-grade-common: #4caf50; // $green-500
$mystery-grade-rare: #03a9f4; // $light-blue-500
$mystery-grade-epic: #8e24aa; // $purple-600
$mystery-grade-legendary: #ffa000; // $amber-700
$mystery-grade-star-prize: #ffc107; // $amber-500
// $mystery-grade-common:          #ad8a52;  // $green-500
// $mystery-grade-rare:            #d7d7d7;  // $light-blue-500
// $mystery-grade-epic:            #c9b037;  // $purple-600
// $mystery-grade-legendary:       #b9f2ff;  // $amber-700
// $mystery-grade-star-prize:      #ffc107;  // $amber-500

// Stats Legend
$legend-1: $cyan-600;
$legend-2: $light-green-600;
$legend-3: $yellow-600;
$legend-4: $orange-600;
$legend-5: $red-600;
$legend-6: $purple-600;
$legend-7: $pink-600;
$legend-8: $blue-600;
$legend-9: $teal-600;
$legend-10: $green-600;

// Progress Bars
$progress-green: $success-core;
$progress-yellow: $warning-core;
$progress-red: $error-core;

// Brand colors
$facebook-color: #3b5998;
$twitter-color: #00aced;
$steam-black: #000;
$steam-blue: #1b2838;
$youtube-color: #f00;
$instagram-color: #e1306c;
$twitch-color: #6441a4;
$discord-color: #7289da;
$reddit-color: #5f99cf;
$paypal-blue: #0079c1;
$paypal-yellow: #ffc439;
$qiwi-color: #ffa834;
$whatsapp-color: #25d366;
$metacritic-green: #6c3;
$metacritic-yellow: #fc3;
$metacritic-red: #f00;
$fandom-cream: #f9edd8;
$fandom-purple: #520044;

// Partner Brands
$bundlestars-core: #d0fe00;
$bundlestars-dark: darken($bundlestars-core, 10%);
$acer-core: #80c342;
$acer-dark: darken($acer-core, 10%);
$hpomen-core: #fe1165;
$hpomen-dark: darken($hpomen-core, 10%);
$hpomen-pink: #ff55e1;
$hpomen-red: #f00;
$hpomen-orange: #f58220;
$gamespot-core: #de004a;
$gamespot-dark: darken($gamespot-core, 10%);
$box-core: #94c23e;
$box-dark: darken($box-core, 10%);
$chilliblast-core: #f9d343;
$chilliblast-dark: darken($chilliblast-core, 10%);
$ccl-core: #28a745;
$ccl-dark: darken($ccl-core, 10%);
$insidetech-core: #0090b9;
$insidetech-dark: darken($insidetech-core, 10%);
$stormforce-core: #fce300;
$stormforce-dark: darken($stormforce-core, 10%);
$razer-core: #44d62c;
$razer-dark: darken($razer-core, 10%);

// Misc
$red-hot-sale-red: #e8372a;
$winter-sale-turquoise: #3d5563;
