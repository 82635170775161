@import "../../styles/_chunk";

.blog-query-container {
  background-color: $blog-bg-inverse-color;
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-radius: $border-radius;

  .tag-name {
    color: $primary-core;
  }

  .query-title {
    font-size: 2rem;
    font-weight: 800;
  }

  .breadcrumb-container {
    font-weight: 400;
    padding-bottom: 0.5rem;
  }

  p {
    padding-bottom: 1rem;
  }
}

.blog-posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-blurb {
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.blog-pinned-card {
  background-color: $blog-bg-inverse-color;
  margin-bottom: 2rem;
  border-radius: $border-radius;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .blog-pinned-img-container {
    width: 55%;
    position: relative;
    overflow: hidden;

    &::before {
      border-top: 50vw solid transparent;
      border-bottom: 100vw solid transparent;
      border-right: 23.5vw solid $blog-bg-inverse-color;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      content: '';
    }
  }

  .blog-pinned-img {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  .btn {
    width: 50%;
  }
}
