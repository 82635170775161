@import "../../styles/_chunk";

.featured-deal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// .double-spaced-text {
//   margin-top: -1rem;
// }

// .double-spaced-text-top {
//   margin-top: 0.5rem;
// }

.featured-deal-card {
  position: relative;
  background-color: $card-background-dark;
  height: auto;
  transition: all ease 0.1s;

  // @include media-breakpoint-only(xs) {
  //   height:
  // }

  @include media-breakpoint-only(md) {
    //
  }

  @include media-breakpoint-only(lg) {
    height: 349px;
  }

  @include media-breakpoint-only(xl) {
    height: 415px;
  }

  @include media-breakpoint-only(xxl) {
    height: 490px;
  }

  @include media-breakpoint-up(lg) {
    @include hover-supported() {
      background-color: $black;
    }
  }

  .hover-dot-screenshot-carousel {
    z-index: 8;
  }

  .card-main-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }

    .responsive-image-island {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 50%;
        flex-shrink: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }

    .partner-logo-container {
      position: absolute;
      z-index: 10;
      width: 100%;

      @include media-breakpoint-only(md) {
        top: -7px;
      }

      .partner-logo-content {
        display: flex;
        background-color: $black;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
      }

      .in-association-text {
        text-transform: uppercase;
        color: $white;
        font-weight: 400;
        font-size: 10px;

        @include media-breakpoint-only(lg) {
          font-size: 7px;
        }
      }

      .partner-logo {
        width: 85px;
        height: 12px;
        margin: 8px;

        @include media-breakpoint-only(md) {
          width: 83px;
          height: 10px;
        }
      }
    }

    .container-blur {
      height: 6px;
      background: linear-gradient(180deg, $black, rgba($black, 0));

      @include media-breakpoint-only(md) {
        height: 4px;
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding: 1rem;
      height: 100%;

      @include media-breakpoint-only(lg) {
        min-height: 182px;
      }

      @include media-breakpoint-only(xl) {
        min-height: 215px;
      }

      .limited-stock {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;
        color: $stardeal-core;
      }

      .featured-slider {
        img {
          max-width: 100%;
        }
      }

      .about-container {
        @include media-breakpoint-up(lg) {
          height: 56px;
          overflow: hidden;
        }

        @include media-breakpoint-up(xl) {
          height: 70px;
        }

        @include media-breakpoint-up(xxl) {
          height: 129px;
        }
      }

      .about-container-small {
        @include media-breakpoint-up(sm) {
          height: 10px;
          overflow: hidden;
        }

        @include media-breakpoint-up(md) {
          height: 40px;
          overflow: hidden;
        }

        @include media-breakpoint-up(lg) {
          height: 42px;
          overflow: hidden;
        }

        @include media-breakpoint-up(xl) {
          height: 50px;
        }

        @include media-breakpoint-up(xxl) {
          height: 110px;
        }
      }

      .progress {
        color: $white;

        @include media-breakpoint-only(md) {
          margin-top: 14px;
        }

        @include media-breakpoint-only(lg) {
          margin-top: 10px;
        }

        @include media-breakpoint-only(xl) {
          margin-top: 16px;
        }
      }

      .stardeal-ends {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 13px;
        margin-top: 14px;
        margin-bottom: 14px;
        // height: 22px;
        // white-space: nowrap;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-only(md) {
          width: 320px;
        }

        @include media-breakpoint-only(lg) {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        @include media-breakpoint-only(xl) {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        b {
          font-weight: 400;
        }

        .ending-soon {
          color: $red-500;
        }
      }
    }
  }

  .hit-card-overlay {
    position: absolute;
    background-color: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    transition: all ease 0.1s;
    padding: 0;
    z-index: 5;
    margin-bottom: $hit-card-stripe-height;

    .overlayContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      padding: 1rem;

      .product-name {
        font-size: 0.875rem;
        line-height: 1.2;
        padding: 0.2rem 1rem 0.1rem;
        text-shadow: 1px 1px 10px $black, 1px 1px 10px $black;
        text-decoration: none;
        color: $text-white;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
          font-size: 1rem;
        }
      }

      .btn-container {
        .btn {
          min-width: 4.75rem;
          padding: 0.475rem 0.25rem 0.5rem;
          margin: 0 4px;
          cursor: pointer;
          font-size: 0.6875rem;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.25px;
          text-align: center;
          z-index: -10;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 36px;

          @include media-breakpoint-up(xl) {
            font-size: 0.75rem;
            margin: 0 5px;
          }
        }
      }

      .wishlist-button {
        color: $grey-8;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
          margin: 0;
          padding: 0;
          width: auto;
        }

        button.wishlist-add {
          color: $primary-core !important;
        }

        button.wishlist-remove {
          color: $grey-8 !important;
          min-width: auto;

          &:hover {
            color: $primary-core !important;
          }
        }
      }
    }
  }

  .featured-slider-container {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    // padding-bottom: 56.25%;
    position: relative;

    @include media-breakpoint-up(xl) {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    .cover-fallback-container {
      padding-bottom: 56.25%;
      position: relative;
    }

    .featured-slider,
    .cover-fallback {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    img {
      border: 0;
      width: 100%;
    }

    .slick-slide {
      min-height: 140px;

      @include media-breakpoint-up(xl) {
        min-height: 172px;
      }
    }
  }

  .card-strip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 0.8125rem;
    transition: all ease 0.1s;
    height: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 0.8125rem;
    }

    .price-container {
      display: flex;
      align-items: center;
      max-height: 24px;

      .card-saving {
        position: relative;
        margin-left: 0;
      }
    }

    .soldout {
      text-transform: uppercase;
      font-weight: 400;
    }

    .best-ever {
      margin-right: 0.5rem;
      color: $stardeal-core;
      text-transform: uppercase;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      font-size: 11px;

      @include media-breakpoint-up(sm) {
        max-width: 100%;
      }

      @include media-breakpoint-up(md) {
        max-width: 110px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 80px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 120px;
        font-size: 12px;
      }
    }

    .was-price {
      color: $grey-5;
      position: relative;
      margin-right: 0.5rem;
      text-decoration: line-through;
    }

    .card-os-icons {
      display: none;
      line-height: 15px;
    }
  }

  @include hover-supported() {
    @include media-breakpoint-up(lg) {
      .hit-card-overlay {
        background-color: rgba($black, 0.95);

        .overlayContent {
          opacity: 1;

          .wishlist-button {
            z-index: 7;
          }

          .btn {
            z-index: 6;
          }
        }
      }
    }

    .card-strip {
      .drm-container {
        display: none;
      }

      .card-os-icons {
        display: block;
        font-size: 13px;
      }

      .card-price-container {
        .card-saving {
          background-color: $grey-11;
        }
      }
    }

    .big-card-sdesc {
      background-color: rgba($black, 1) !important;
    }
  }

  .faux-block-link__overlay-link {
    &::before {
      bottom: -$hit-card-stripe-height;
    }
  }

  // Styling for horizontal full width card

  &.horizontal {
    .card-main-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }

      .responsive-image-island {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 40%;
          flex-shrink: 0;
        }

        @include media-breakpoint-up(xl) {
          width: 36%;
        }
      }

      .content-container {
        min-height: 0;

        @include media-breakpoint-up(xl) {
          padding: 1.5rem;
        }

        .horizontal-name {
          display: none;

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }

        .about-container {
          margin-bottom: 1rem;

          @include media-breakpoint-up(md) {
            height: 26px;
            overflow: hidden;
          }

          @include media-breakpoint-up(xl) {
            height: 45px;
            margin-bottom: 1.25rem;
          }
        }

        .os-drm-icons-container {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          .card-os-icons {
            display: block;
            margin-left: 1rem;
          }
        }
      }
    }

    .hit-card-overlay {
      margin: 0;

      .overlayContent {
        flex-direction: row;
        justify-content: flex-start;
        padding: 0;
        color: $text-white;

        .featured-slider-container {
          padding: 0;
          margin: 0;
          width: 40%;
          height: 214px;
          overflow: hidden;
          flex-shrink: 0;
          margin-bottom: -1px;

          @include media-breakpoint-up(xl) {
            width: 36%;
            height: 228px;
          }

          img {
            border: 0;
          }
        }

        .details-container {
          width: 100%;
          height: 80%;
          padding: 1rem;
        }
      }
    }

    @include hover-supported() {
      .hit-card-overlay {
        background-color: transparent;

        .overlayContent {
          opacity: 0;
        }
      }

      .card-strip {
        .drm-container {
          display: flex;
        }
      }
    }
  }

  &.card-light-theme {
    background-color: map-get($light-theme, card-background);

    .progress {
      background-color: $grey-4;
    }

    .card-price-container {
      .card-saving {
        background-color: $grey-2;
        color: $text-black;
      }
    }

    @include hover-supported() {
      background-color: $black;
      color: $text-white;

      .card-saving {
        color: $text-white;
      }
    }
  }

  @include theme(light) {
    @extend .card-light-theme;

    box-shadow: map-get($light-theme, card-box-shadow);
  }
}
