@import "../../../styles/_chunk";

.blog-posts-list {
  border-bottom: 1px solid $grey-3;
  padding-bottom: 10px;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  .blog-list-img {
    width: 90px;
    flex-shrink: 0;
  }

  .blog-list-title {
    font-size: 14px;
    color: $text-black;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 46px;
    margin: 0;
    font-weight: 400;
  }

  .blog-meta {
    font-size: 13px;
    font-style: italic;
    color: $grey-7;
    font-weight: 400;
    margin-bottom: 0;
  }

  @media #{$retina-screen} {
    .blog-meta {
      font-weight: inherit;
    }
  }

  &:hover {
    text-decoration: none;

    .blog-list-title {
      color: $primary-core;
    }
  }
}
