@import "../../styles/_chunk";

.blog-header {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px !important;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    height: 200px !important;
  }

  @include media-breakpoint-up(lg) {
    height: 230px !important;
  }

  .heading-container {
    padding-top: 1.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 4rem;
    }

    .blog-header-title {
      text-align: center;
      text-transform: uppercase;
      color: $white;
      font-size: 1.8rem;
      padding-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }

      span {
        font-weight: 800;
        color: $primary-core;
      }
    }

    .blog-header-point-container {
      display: flex;
      justify-content: space-between;

      .center {
        border-radius: $border-radius;
        display: block;
        font-weight: 400;
        width: 65%;
        flex-wrap: wrap;
        justify-content: space-around;
        line-height: 18px;

        @include media-breakpoint-up(md) {
          display: flex;
          line-height: 15px;
          width: 90%;
        }

        @include media-breakpoint-up(lg) {
          width: 80%;
        }

        @include media-breakpoint-up(xl) {
          width: 67%;
        }

        @include media-breakpoint-up(xxl) {
          width: 57%;
        }

        .no-border {
          border: 0 !important;
        }

        .blog-header-point {
          text-align: center;
          color: $white;

          @include media-breakpoint-up(md) {
            border: 1px solid $primary-core;
            border-top: 0;
            border-bottom: 0;
            border-left: 0;
            flex-grow: 1;
            font-size: 0.85rem;
            letter-spacing: 0.5px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
