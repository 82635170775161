@import "../../styles/_chunk";

.blog-warning-bar {
  background-color: $red-600;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;
  padding: 1rem;
  // position: absolute;
  z-index: 1;
}

.blog-post-page {
  @include media-breakpoint-up(md) {
    padding-top: 1rem;
  }

  .blog-article-container {
    background-color: $blog-bg-inverse-color;
    margin-bottom: 2rem;

    p {
      font-weight: 400;
      font-size: 0.9375rem;
    }

    @media #{$retina-screen} {
      p {
        font-weight: inherit;
      }
    }

    .blog-buy-now-container {
      margin-top: 1.5rem;
    }

    .blog-post-hr {
      border-color: $grey-2;
    }

    .blog-post-share {
      .blog-post-share-btn-container {
        justify-content: flex-start !important;
      }

      .react-sharing-button__link {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-of-type {
          margin-left: 0;
        }
      }

      .react-sharing-button__text {
        display: none;
      }
    }

    .hit-mega-card {
      .name-description-container {
        padding: 0.5rem 1rem 0 1rem !important;
      }

      .product-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
      }

      .top-product-description {
        font-size: 13px !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-only(lg) {
          -webkit-line-clamp: 2;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 14px !important;
        }
      }
    }

    .blog-wishlist-banner {
      width: 100%;
      height: 0;
      padding-bottom: 19.53%;

      img {
        width: 100%;
      }
    }
  }

  .blog-posts-list-container {
    &:last-of-type {
      .blog-posts-list {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  h1.blog-post-title {
    font-weight: 400;
    font-size: 1.75rem;
  }

  .blog-page-intro {
    border-left: 3px solid $blue-grey-600;
    padding: 1rem;
    color: $blue-grey-600;
    background-color: $blog-bg-color;

    p {
      margin: 0;
      font-size: 1.125rem;
    }
  }

  .blog-post-date {
    background-color: $blue-grey-400;
    color: $white;
    width: 84px;
    height: 84px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      text-transform: uppercase;
    }

    p.date-number {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  p.blog-post-body:first-of-type {
    &::first-letter {
      font-size: 220%;
      color: $blue-grey-600;
    }
  }

  a.blog-link {
    color: $primary-core;
    font-weight: bold;
  }

  .blog-grouped-content {
    margin-bottom: 2rem;

    h2 {
      font-weight: 400;
    }

    table {
      width: auto;
      max-width: 100%;

      td,
      th {
        padding: 4px 5px;
        vertical-align: top;

        &:not(:first-child) {
          border-left: 1px solid $grey-3;
        }

        @include media-breakpoint-up(md) {
          padding: 2px 10px;
        }
      }

      th {
        border-bottom: 1px solid $grey-3;
        font-weight: 600;
      }
    }
  }

  .blog-video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    margin-bottom: 1rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .blog-page-related {
    background-color: $blog-bg-inverse-color;
  }

  a.blog-tag {
    color: $text-black;
    text-decoration: none;
    background-color: $blog-bg-color;
    padding: 0.5rem;
    margin: 1.5px;

    &:hover {
      background-color: $primary-core;
    }
  }

  .blog-post-category-games,
  .blog-post-category-news,
  .blog-post-category-develop,
  .blog-post-category-community {
    color: $white !important;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 10px;
    padding: 0 5px 0 5px;
    letter-spacing: 0.1px;
    font-size: 13px;
    line-height: 23px;

    @include media-breakpoint-up(md) {
      font-size: 10px;
    }
  }

  .blog-post-category-games {
    background-color: $label-primary;
  }

  .blog-post-category-news {
    background-color: $label-navy;
  }

  .blog-post-category-develop {
    background-color: $label-green;
  }

  .blog-post-category-community {
    background-color: $label-purple;
  }
}

.smcx-embed {
  max-width: 100% !important;
  min-height: 500px;
}

.instagram-media {
  max-width: 100% !important;
}

.smcx-embed > .smcx-iframe-container {
  max-width: 100% !important;
  min-height: 500px;
}
